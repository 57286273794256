import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { usePage, useWine } from '../stores'
import { wineDetails } from '../constants/wine-details'

function Branding() {
  const page = usePage()
  const { wineId } = useWine()

  const wineid = wineDetails.filter((id) => {
    return id.wineId == wineId
  })

  const brandName = wineid.map((brand) => {
    return brand.logo_src
    // isIOS && url.iOSAR
  })

  return (
    <>
      {page.currentPage === 'Wine3D' && (
        <>
          <div
            className={css(styles.LogoContainer)}
            style={{
              width: `${
                brandName == './images/jc/branding/CV_gold.png'
                  ? '22vh'
                  : brandName == './images/jc/branding/BE_logo.png'
                  ? '35vh'
                  : '25vh'
              }`,
              top: `${
                brandName == './images/jc/branding/CV_gold.png'
                  ? '0.8vh'
                  : brandName == './images/jc/branding/BE_logo.png'
                  ? '1vh'
                  : '0.5vh'
              }`,
            }}
          >
            <img alt="Logo" className={css(styles.Logo)} src={brandName} />
          </div>
        </>
      )}
      {page.currentPage === 'Questionaire' && (
        <div className={css(styles.LogoContainer)} style={{ zIndex: 2, width: '25vh', top: '0vh' }}>
          <img
            alt="Logo"
            className={css(styles.Logo)}
            src="./images/jc/branding/JC_updatedLogo.png"
          />
        </div>
      )}
    </>
  )
}

export default Branding

const styles = StyleSheet.create({
  LogoContainer: {
    position: 'absolute',
    // width: '30vh',
    height: 'auto',
    textAlign: 'center',
    // top: '3vh',
    left: '50%',
    maxWidth: '35vh',
    transform: 'translateX(-50%)',
  },
  Logo: {
    width: '100%',
    height: 'auto',
  },
})
