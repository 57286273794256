import { useEffect } from 'react'
import { WineMap } from '../constants/wine-mapping'
// import { Mapping } from '../constants/wine-mapping'
import { useQuestion, useWine, useLoading } from '../stores'

function WineMapLogic() {
  const { queAId, queBId, setQueSetB } = useQuestion()
  const { wineMapping, setWineId, setWineMapping } = useWine()
  const loader = useLoading()
  const diorama = useQuestion()

  const filterQue = WineMap.filter((id) => {
    return id.setA_id === queAId
  })

  const dioramaID = filterQue.map((id) => {
    if (id.setA_id === queAId) {
      return id.diorama_id
    }
  })

  const filterWineId = filterQue.map((id) => {
    return id.setB.map((id01) => {
      if (id01.setB_id === queBId) {
        return id01.wine_id
      }
    })
  })

  function wineMap() {
    // console.log('func: ' + dioramaID)
    // console.log('filter b: ' + filterWineId.toString().replace(/,/g, ''))

    const wineID = filterWineId.toString().replace(/,/g, '')
    diorama.setDioramaId(dioramaID)
    setWineId(wineID)
    setQueSetB(false)
    setWineMapping(false)
    // page.setCurrentPage('WineLoading')
    loader.setWineLoading(true)
  }
  useEffect(() => {
    return wineMap()
  }, [wineMapping])

  return null
}

export default WineMapLogic
