import { Euler, Vector2, Vector3 } from 'three'
import * as THREE from 'three'

const AnimationConfig = {
  state: [
    {
      name: 'Introduce',
      range: new Vector2(0, 3),
    },
    {
      name: 'Diorama',
      range: new Vector2(3, 7),
      targetPosition: new Vector3(0, 0, 0),
      targetRotation: new Euler(0, 0, 0, 'XYZ'),
    },
  ],
  bottleInitialLocation: new Vector3(0.05, -0.2, 0.6),
}

export function map_range(value, low1, high1, low2, high2) {
  let mapRange = low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
  if (mapRange < low2) return low2
  else if (mapRange > high2) return high2
  else return mapRange
}

function isInRangeInc(val, min, max) {
  if (val >= min && val <= max) return true
  else return false
}

function isInRange(val, min, max) {
  if (val > min && val < max) return true
  else return false
}

export function GetScrollState(val) {
  for (var x = 0; x < AnimationConfig.state.length; x++) {
    if (x % 2) {
      if (isInRange(val, AnimationConfig.state[x].range.x, AnimationConfig.state[x].range.y))
        return AnimationConfig.state[x].name
    } else {
      if (isInRangeInc(val, AnimationConfig.state[x].range.x, AnimationConfig.state[x].range.y))
        return AnimationConfig.state[x].name
    }
  }
}

export function ModelLerp(dioramaName, wineRef, dioramaRef, value) {
  dioramaRef.position.y = THREE.MathUtils.lerp(-1400, 0, map_range(value, 5, 6.9, 0, 1))
  if (dioramaName === 'sunday_afternoon') {
    // dioramaRef.position.y = THREE.MathUtils.lerp(-1400, 50, map_range(value, 5, 6.9, 0, 1))
    wineRef.position.x = THREE.MathUtils.lerp(-18, 380, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.y = THREE.MathUtils.lerp(-40, 163, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.z = THREE.MathUtils.lerp(0, 110, map_range(value, 6.5, 6.9, 0, 1))
  } else if (dioramaName === 'romantic_dinner') {
    // dioramaRef.position.y = THREE.MathUtils.lerp(-1400, 70, map_range(value, 5, 6.9, 0, 1))
    wineRef.position.x = THREE.MathUtils.lerp(-18, 218, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.z = THREE.MathUtils.lerp(0, 40, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.y = THREE.MathUtils.lerp(-40, 208, map_range(value, 6.5, 6.9, 0, 1))
  } else if (dioramaName === 'movie_night') {
    // dioramaRef.position.y = THREE.MathUtils.lerp(-1400, 30, map_range(value, 5, 6.9, 0, 1))
    wineRef.position.x = THREE.MathUtils.lerp(-18, -65, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.z = THREE.MathUtils.lerp(0, -17.33, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.y = THREE.MathUtils.lerp(-40, 90, map_range(value, 6.5, 6.9, 0, 1))
  } else if (dioramaName === 'festive_season') {
    // dioramaRef.position.y = THREE.MathUtils.lerp(-1400, -5, map_range(value, 5, 6.9, 0, 1))
    wineRef.position.x = THREE.MathUtils.lerp(-18, 120, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.z = THREE.MathUtils.lerp(0, -240, map_range(value, 6.5, 6.9, 0, 1))
    wineRef.position.y = THREE.MathUtils.lerp(-40, 97, map_range(value, 6.5, 6.9, 0, 1))
  }
}

var curve = new THREE.CatmullRomCurve3([
  new THREE.Vector3(66.89289284895715, 11.57768837879978, 167.23236558599436),
  new THREE.Vector3(198.55365065140967, -26.212046889651052, -94.32354642851716),
  new THREE.Vector3(-139.95706022901342, -7.820395593571235, -186.1527939753048),
])

var curve1 = new THREE.CatmullRomCurve3([
  new THREE.Vector3(-139.95706022901342, -7.820395593571235, -186.1527939753048),
  new THREE.Vector3(-231.3645447798464, -22.21328136939149, 16.703818414310035),
  new THREE.Vector3(-168.31647326714253, -32.95510739570846, 400),
  new THREE.Vector3(31, 21, 600),
  new THREE.Vector3(2405.7762209730327, 781.449478969882, 3251.9381980137905),
])

export let points = curve.getPoints(1000)
export let points1 = curve1.getPoints(3000)

export const gaConfig = {
  enabled: true,
  code: 'G-F9DE6H6MYD',
}
