import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useQuestion } from '../stores'
import { fadeIn, fadeOut } from 'react-animations'

function Background() {
  const question = useQuestion()
  const [, setRender] = useState(false)

  useEffect(() => {
    if (question.optionUI) {
      setRender(true)
    }
  }, [question.optionUI])

  // const onAnimationEnd = () => {
  //   if (!question.optionUI) setRender(false)
  // }

  // const backgroundAnim = useSpring({
  //   opacity: question.optionUI ? 1 : 0,
  // })
  return (
    <>
      {/* {render && ( */}
      <div
        className={css(
          styles.container
          // question.optionUI ? styles.fadeInAnim : styles.fadeOutAnim
        )}
        // onAnimationEnd={onAnimationEnd}
      >
        <img alt="Logo" className={css(styles.image)} src="./images/jc/bg/bg.jpg" />
      </div>
      {/* )} */}
    </>
  )
}

export default Background

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '0.3s',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
  },
  container: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFill: 'contain',
  },
})
