import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { usePage } from '../stores'
import { fadeIn, fadeOut } from 'react-animations'

function LogoLoadingScreen() {
  const page = usePage()
  const [render, setRender] = useState(false)

  function FadeOutLoading() {
    // console.log('fade out called')
    setTimeout(() => {
      page.setCurrentPage('Landing')
      // loader.setLogoLoading(false)
      // page.setLandingPage(true)
    }, 500)
  }

  useEffect(() => {
    if (page.retryExp) {
      setTimeout(() => {
        // loader.setLogoLoading(false)
        page.setRetryExp(false)
        page.setCurrentPage('Landing')
      }, 1500)
    }
  }, [page.retryExp])

  useEffect(() => {
    if (page.currentPage === 'LogoLoading') {
      setRender(true)
    }
  }, [page.currentPage === 'LogoLoading'])

  const onAnimationEnd = () => {
    if (page.currentPage != 'LogoLoading') setRender(false)
  }
  return (
    <>
      {render && (
        <div
          className={css(
            styles.wrapper,
            page.currentPage === 'LogoLoading' ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={css(styles.video)}>
            <video
              width="320vh"
              height="240vh"
              id="logoVideo"
              autoPlay
              muted
              playsInline
              onEnded={() => {
                !page.retryExp && FadeOutLoading()
              }}
            >
              {/* <source src={SplashVideo} type="video/webm" /> */}
              <source src="./video/Splash1.mp4" type="video/mp4" />
            </video>
          </div>
          <hr className={css(styles.line)} />
          <div className={css(styles.WineGuideText1)}>WINE GUIDE</div>
          <div className={css(styles.WineGuideText2)}>
            THE PERFECT WINE,
            <br />
            FOR EVERY OCCASION
          </div>
          <div className={css(styles.WineGuideText3)}>DRINK RESPONSIBLY</div>
        </div>
      )}
    </>
  )
}

export default LogoLoadingScreen

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    zIndex: 1,
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
  },
  Logo: {
    width: '40%',
    marginTop: '80%',
  },
  video: {
    textAlign: 'center',
  },
  line: {
    width: '50%',
  },
  WineGuideText1: {
    marginTop: '7%',
    fontFamily: 'Gotham-bold',
    letterSpacing: '0.2rem',
    fontSize: '2.5vh',
  },
  WineGuideText2: {
    marginTop: '4%',
    fontFamily: 'Gotham',
    letterSpacing: '0.2rem',
    fontSize: '1.8vh',
    height: 'auto',
    lineHeight: '150%',
  },
  WineGuideText3: {
    marginTop: '7%',
    fontFamily: 'Gotham',
    letterSpacing: '0.2rem',
    fontSize: '2.3vh',
    height: 'auto',
  },
})
