import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import * as THREE from 'three'
import PropTypes from 'prop-types'

let toneMappingOptions = {
  None: THREE.NoToneMapping,
  Linear: THREE.LinearToneMapping,
  Reinhard: THREE.ReinhardToneMapping,
  Cineon: THREE.CineonToneMapping,
  ACESFilmic: THREE.ACESFilmicToneMapping,
}

let tonemappingParams = {
  exposure: 0.6,
  toneMapping: 'Linear',
}

export default function Skybox({ hdr }) {
  const { gl, scene } = useThree()

  useEffect(() => {
    console.log('Skybox Useeffect')
    gl.physicallyCorrectLights = true
    gl.toneMapping = toneMappingOptions[tonemappingParams.toneMapping]
    gl.toneMappingExposure = tonemappingParams.exposure
    // gl.sortObjects = true
    // gl.outputEncoding = THREE.sRGBEncoding
    gl.pixelRatio = 1.5

    let pmremGenerator = new THREE.PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader()
      .setDataType(THREE.FloatType)
      .setPath('./')
      .load(hdr, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture
        scene.background = envMap
        scene.environment = envMap
        texture.dispose()
        pmremGenerator.dispose()
      })
  }, [gl, hdr])

  return null
}

Skybox.propTypes = {
  hdr: PropTypes.string,
}
