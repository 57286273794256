import React, { useLayoutEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import background from '../images/blurBg.jpg'
import { usePage } from '../stores'
import { sendEvent } from '../../../helper/google-analytics'

const MinAge = 25
const MaxAge = 110

function YearValidation() {
  const [year, setYear] = useState({
    value: null,
    isYear: false,
    isValid: false,
  })
  const yearRef = useRef()
  const page = usePage()
  // const loader = useLoading()

  useLayoutEffect(() => {
    if (year.isYear) {
      var d = new Date()
      var n = d.getFullYear()

      if (year.value < n) {
        let age = n - year.value

        let val = year.value

        if (age > MinAge) {
          if (!year.isValid) {
            setYear({
              value: val,
              isYear: true,
              isValid: true,
            })
          }
        } else {
          if (year.isValid) {
            setYear({
              value: val,
              isYear: true,
              isValid: false,
            })
          }
        }
      }
    }
  }, [year])

  function OnInput(e) {
    let yearX = e.target.value

    var d = new Date()
    var n = d.getFullYear()

    let diff = n - yearX

    if (yearX / 1000 > 1 && diff < MaxAge) {
      setYear({
        value: yearX,
        isYear: true,
        isValid: false,
      })
    } else {
      setYear({ value: null, isYear: false, isValid: false })
    }
  }

  function SubmitKeyboard(e) {
    if (e.key === 'Enter') {
      Submit()
    }
  }

  function Submit() {
    if (year.isYear && year.isValid) {
      sendEvent('Welcome', 'DOB', year.value)
      // props.OnAgeVerified()
      // screen.setYearValidationScreen(false)
      // loader.setLogoLoading(true)
      page.setCurrentPage('LogoLoading')
    } else return
  }
  return (
    <>
      {/* {screen.yearValidationScreen && ( */}
      <div className={css(styles.container)}>
        <div className={css(styles.logoContainer)}>
          <img
            alt="logo"
            className={css(styles.Logo)}
            src={'./images/jc/branding/JC_updatedLogo.png'}
          />
        </div>
        <div className={css(styles.Welcome)}>WELCOME</div>
        <div className={css(styles.description)}>Please enter your date of birth</div>
        <div className={css(styles.InputContainer)}>
          <input
            ref={yearRef}
            placeholder={'YYYY'}
            className={css(
              year.isYear && !year.isValid ? styles.InvalidYearInput : styles.YearInput
            )}
            type={'number'}
            onChange={OnInput}
            maxLength={4}
            onKeyDown={SubmitKeyboard}
            style={{
              backgroundColor: year.isYear && !year.isValid && '#FFB4B4',
              color: year.isYear && !year.isValid && '#FF3636',
            }}
          />
        </div>
        <div className={css(styles.ButtonContainer)}>
          {year.isYear && year.isValid && (
            <button className={css(styles.SubmitButton)} onClick={Submit}>
              Enter
            </button>
          )}
          {(!year.isYear || !year.isValid) && (
            <button className={css(styles.SubmitButton)} disabled>
              Enter
            </button>
          )}
        </div>
        <div className={css(styles.BottomContainer)}>
          <div
            className={css(
              year.isYear && !year.isValid ? styles.InvalidBottomText : styles.BottomText
            )}
          >
            You have to be over 25 to enter this site
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  )
}

export default YearValidation

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  BottomContainer: {
    backgroundColor: 'white',
    bottom: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    height: '12vh',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  BottomText: {
    fontFamily: 'Gotham',
    fontWeight: 'bold',
    fontSize: '2.3vh',
    width: '75vw',
  },
  InvalidBottomText: {
    fontFamily: 'Gotham',
    fontWeight: 'bold',
    fontSize: '2.5vh',
    width: '75vw',
    color: '#FF3636',
  },
  SubmitButton: {
    outline: 'none',
    border: 'none',
    backgroundColor: 'white',
    color: 'black',
    fontFamily: 'Gotham',
    fontSize: '2.5vh',
    height: '5vh',
    width: '27vw',
    ':hover': {
      backgroundColor: '#C9C9C9',
      border: 'none',
      textDecoration: 'none',
      outline: 'none',
    },
    ':active': {
      backgroundColor: '#C9C9C9',
      border: 'none',
      textDecoration: 'none',
      outline: 'none',
    },
    ':disabled': {
      backgroundColor: '#C9C9C9',
      color: 'white',
      border: 'none',
      textDecoration: 'none',
      outline: 'none',
    },
  },
  ButtonContainer: {
    textAlign: 'center',
    paddingTop: '2vh',
  },
  InputContainer: {
    textAlign: 'center',
    paddingTop: '4vh',
  },
  YearInput: {
    width: '24vw',
    height: '6vh',
    outline: 'none',
    fontFamily: 'Gotham',
    fontSize: '2.8vh',
    textAlign: 'center',
    border: 'solid 0.15vh',
    borderRadius: '0.5vh',
    fontWeight: 'bold',
    padding: '0.8vh',
    // backgroundColor: '#ffffff',
  },
  InvalidYearInput: {
    width: '24vw',
    height: '6vh',
    fontSize: '2.8vh',
    outline: 'none',
    fontFamily: 'Gotham',
    textAlign: 'center',
    border: 'solid 0.15vh',
    // backgroundColor: '#FFB4B4',
    // color: '#FF3636',
    fontWeight: 'bold',
    borderColor: '#FF3636',
    padding: '0.8vh',
    borderRadius: '0.5vh',
  },
  Welcome: {
    fontFamily: 'Gotham',
    fontWeight: 'bolder',
    fontSize: '5vh',
    textAlign: 'center',
    paddingTop: '4vh',
    letterSpacing: '0.05vw',
    color: 'white',
  },
  description: {
    fontFamily: 'Gotham',
    fontWeight: 'bolder',
    fontSize: '2vh',
    textAlign: 'center',
    paddingTop: '3vh',
    color: 'white',
  },
  logoContainer: {
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '8vh',
  },
  Logo: {
    width: '90vw',
    height: 'auto',
  },
})
