import React, { useEffect, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import Lottie from 'react-lottie'
import animationData from '../lottie/scroll.json'
import { usePage, useWine } from '../stores'

function ScollIndicator() {
  const animation = useRef()
  const scrollInd = useWine()
  const page = usePage()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (animation.current != null) animation.current.anim.setSpeed(-1)
  })
  return (
    <>
      {page.currentPage === 'Wine3D' && scrollInd.scrollIndicator && (
        <div className={css(styles.LottieBackground)}>
          <Lottie ref={animation} options={defaultOptions} height={250} width={250} />
        </div>
      )}
    </>
  )
}

export default ScollIndicator

const styles = StyleSheet.create({
  LottieBackground: {
    // zIndex: 0,
    position: 'absolute',
    // top: 0,
    bottom: '3vh',
    left: 0,
    right: 0,
    // paddingTop: '60vh',
    textAlign: 'center',
    zIndex: 2,
    pointerEvents: 'none',
  },
})
