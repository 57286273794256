import React, { useEffect, useState } from 'react'
import { usePage, useQuestion } from '../stores'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut } from 'react-animations'

function LeafLoadingScreen() {
  // const loader = useLoading()
  // const option = useQuestion()
  const { setQueSetA, queSetA, setQueSetB } = useQuestion()
  const [render, setRender] = useState()
  const page = usePage()

  useEffect(() => {
    // console.log('leaf laoding: ' + loader.leafLoading)
    if (page.currentPage === 'LeafLoading') {
      // console.log('leaf laoding: ' + loader.leafLoading)
      setRender(true)
      setTimeout(() => {
        // loader.setLeafLoading(false)
        setQueSetB(false)
        page.setCurrentPage('Questionaire')
        if (!queSetA) {
          setQueSetA(true)
        }
        // setTimeout(() => {
        //   // option.setOptionUI(true)
        //   if (!queSetA) {
        //     setQueSetA(true)
        //   }
        // }, 500)
      }, 1000)
    }
  }, [page.currentPage === 'LeafLoading'])

  const onAnimationEnd = () => {
    if (page.currentPage != 'LeafLoading') setRender(false)
  }

  return (
    <>
      {render && (
        <div
          className={css(
            styles.container,
            page.currentPage === 'LeafLoading' ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={css(styles.gif)}>
            <img src="./images/jc/gif/Loading-2.gif" alt="loading" loop="infinite" />
          </div>
        </div>
      )}
    </>
  )
}

export default LeafLoadingScreen

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 1,
  },
  gif: {
    paddingTop: '20%',
    height: '40%',
    textAlign: 'center',
  },
})
