import React, { useEffect, useState } from 'react'
import { useLoading, usePage, useQuestion, useWine } from '../stores'
import { css, StyleSheet } from 'aphrodite'
import { fadeIn, fadeOut, slideInUp, slideOutDown } from 'react-animations'
import { sendEvent } from '../../../helper/google-analytics'

function LandingPage() {
  const page = usePage()
  const [render, setRender] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [renderPopup, setRenderPopup] = useState(false)
  const loader = useLoading()
  const { setWineId } = useWine()
  const randomNumber = 11
  const dioramaRandomNumb = 4
  const dioramaModel = useQuestion()

  useEffect(() => {
    if (page.currentPage === 'Landing') {
      setRender(true)
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (loaded) {
      setRenderPopup(true)
    }
  }, [loaded])

  function FadeOutPage() {
    sendEvent('Navigation', 'Click', 'Lets Go')
    // console.log('wine 3d: ' + wine3D + 'wine id: ' + wineId)
    setLoaded(false)
    setTimeout(() => {
      page.setCurrentPage('LeafLoading')
      // page.setLandingPage(false)
      // loader.setLeafLoading(true)
    }, 1000)
  }

  function RequestRandom() {
    sendEvent('Navigation', 'Click', 'Pick for Me')

    let randomId = Math.floor(Math.random() * randomNumber) + 1
    let dioramaRandomId = Math.floor(Math.random() * dioramaRandomNumb) + 1

    setWineId(randomId)
    dioramaModel.setDioramaId(dioramaRandomId)

    // page.setLandingPage(false)
    // loader.setWineLoading(true)
    setLoaded(false)
    setTimeout(() => {
      loader.setWineLoading(true)
    }, 1000)

    // console.log('random:  ' + randomId)
  }

  const onAnimationEnd = () => {
    if (page.currentPage != 'Landing') setRender(false)
  }

  const onPopupAnimationEnd = () => {
    if (!loaded) setRenderPopup(false)
  }

  return (
    <>
      {render && (
        <div
          className={css(
            styles.container,
            page.currentPage === 'Landing' ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={css(styles.LogoContainer)}>
            <img alt="Logo" className={css(styles.Logo)} src="./images/jc/branding/JC_Gold.png" />
          </div>
          <div className={css(styles.MainText)}>WINE GUIDE</div>

          {renderPopup && (
            <div
              className={css(
                styles.BottomHolderIntro,
                loaded ? styles.SlideUpAnim : styles.SlideDownAnim
              )}
              onAnimationEnd={onPopupAnimationEnd}
            >
              <div className={css(styles.Button)}>
                <div className={css(styles.BottomTextHeader)}>
                  ARE YOU READY TO PICK THE PERFECT WINE?
                </div>
                <div className={css(styles.ButtonHolder)}>
                  <div className={css(styles.ButtonWrapper)}>
                    <button
                      onClick={() => {
                        FadeOutPage()
                      }}
                      className={css(styles.MainButton)}
                    >
                      LET&apos;S GO
                    </button>
                  </div>
                  <div className={css(styles.ButtonWrapper)}>
                    <button
                      onClick={() => {
                        RequestRandom()
                      }}
                      className={css(styles.MainButton)}
                    >
                      PICK FOR ME
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LandingPage

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  SlideUpAnim: {
    animationName: slideInUp,
    animationDuration: '1s',
  },
  SlideDownAnim: {
    animationName: slideOutDown,
    animationDuration: '0.5s',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url(./images/jc/bg/vineYard.jpg)',
    backgroundPosition: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
  },
  Button: {
    height: '100%',
  },
  LogoContainer: {
    marginTop: '2.6vh',
    width: '36vh',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  Logo: {
    width: '100%',
    height: '100%',
  },
  MainText: {
    fontSize: '3.7vh',
    color: 'white',
    fontFamily: 'Gotham',
    textAlign: 'center',
    marginTop: '2.5%',
  },
  BottomHolderIntro: {
    position: 'absolute',
    backgroundColor: '#9A2849',
    left: 0,
    right: 0,
    bottom: 0,
    // animationName: FlyInBottom,
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease',
    animationDuration: '3s',
    height: '20vh',
    // zIndex: 101,
  },
  BottomTextHeader: {
    textAlign: 'center',
    fontSize: '1.8vh',
    paddingTop: '5%',
    paddingLeft: '4%',
    paddingRight: '4%',
    marginRight: 'auto',
    marginLeft: 'auto',
    lineHeight: '150%',
    color: 'white',
    fontFamily: 'Gotham',
    letterSpacing: 2,
    // height: '30%',
    // background: 'red',
  },
  ButtonHolder: {
    textAlign: 'center',
    // height: '70%',
    paddingTop: '6%',
  },
  MainButton: {
    height: '5vh',
    width: '18vh',
    padding: 0,
    background: 'none',
    backgroundColor: 'white',
    border: 'none',
    fontFamily: 'Gotham',
    letterSpacing: '0.1rem',
    fontSize: '1.7vh',
    color: '#000000',
  },
  ButtonWrapper: {
    width: '50%',
    float: 'left',
  },
})
