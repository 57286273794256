import { Html, useScroll } from '@react-three/drei'
import React, { useEffect, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { a as web } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import { useControls } from '../stores'

function WineContent({ content, hdr }) {
  const scroll = useScroll()
  const [content01, setContent01] = useState(false)
  const [content02, setContent02] = useState(false)
  const [content03, setContent03] = useState(false)
  const [textColor, setTextColor] = useState('#000000')
  let hdrString = hdr.substring(hdr.lastIndexOf('/') + 1, hdr.length)
  const control = useControls()

  useFrame(() => {
    let val = scroll.offset * 10
    if (val < 0.4) {
      setContent01(true)
    } else if (val > 0.5 && val < 1.1) {
      setContent01(false)
      setContent03(false)
      setContent02(false)
    } else if (val > 1.2 && val < 1.7) {
      setContent01(false)
      setContent02(true)
      setContent03(false)
    } else if (val > 1.7 && val < 2.8) {
      setContent01(false)
      setContent02(false)
      setContent03(false)
    } else if (val > 2.9 && val < 3.4) {
      setContent01(false)
      setContent02(false)
      setContent03(true)
    } else if (val > 3.5) {
      setContent01(false)
      setContent02(false)
      setContent03(false)
    }
  })

  const anim01 = useSpring({
    opacity: content01 ? 1 : 0,
  })
  const anim02 = useSpring({
    opacity: content02 ? 1 : 0,
  })
  const anim03 = useSpring({
    opacity: content03 ? 1 : 0,
  })

  useEffect(() => {
    if (hdrString === 'moonless_golf_b.hdr') {
      setTextColor('#ffffff')
    } else {
      setTextColor('#ffffff')
    }
  }, [hdr])

  return (
    <>
      {!control.orbitControls && (
        <group>
          <group position={[-26, 35, 0]}>
            <Html style={{ pointerEvents: 'none' }} rotation={[0, 0.44, 0]}>
              <web.div style={anim01} className={css(styles.sec01)}>
                <div className={css(styles.sec01_header)} style={{ color: '#ffffff' }}>
                  {content[0].header}
                </div>
                <div className={css(styles.sec01_subText)} style={{ color: '#ffffff' }}>
                  {content[0].subtext}
                </div>
              </web.div>
            </Html>
          </group>
          {/* Taste */}
          <group rotation={[0, 2, 0]} position={[55, 15, -25]}>
            <Html style={{ pointerEvents: 'none' }}>
              <web.div style={anim02} className={css(styles.sec01, styles.secWidth)}>
                <div
                  className={css(styles.sec01_header)}
                  style={{
                    color: `${textColor}`,
                    fontWeight: 700,
                  }}
                >
                  {content[1].header}
                </div>
                <div
                  className={css(styles.sec01_subText)}
                  style={{
                    color: `${textColor}`,
                    fontWeight: 700,
                  }}
                >
                  {content[1].subtext}
                </div>
              </web.div>
            </Html>
          </group>
          {/* Enjoy with */}
          <group position={[-6.67, 25, -20]} rotation={[0, 3.6, 0]}>
            <Html style={{ pointerEvents: 'none' }}>
              <web.div style={anim03} className={css(styles.sec01, styles.secWidth)}>
                <div
                  className={css(styles.sec01_header, styles.color)}
                  style={{ color: `${textColor}` }}
                >
                  {content[2].header}
                </div>
                <div
                  className={css(styles.sec01_subText, styles.color)}
                  style={{ color: `${textColor}` }}
                >
                  {content[2].subtext}
                </div>
              </web.div>
            </Html>
          </group>
        </group>
      )}
    </>
  )
}

export default WineContent

WineContent.propTypes = {
  content: PropTypes.array,
  hdr: PropTypes.string,
}

const styles = StyleSheet.create({
  sec01: {
    maxWidth: '35vh',
    width: '28vh',
    // background: 'red',
  },
  secWidth: {
    width: '28vh',
  },
  sec01_header: {
    fontSize: '2vh',
    letterSpacing: 0.05,
    pointerEvents: 'none',
    fontFamily: 'Gotham',
    // fontWeight: `${isIOS ? 'normal' : 'bold'}`,
    // color: '#ffffff',
  },
  sec01_subText: {
    fontSize: '1.8vh',
    lineHeight: '130%',
    letterSpacing: 0.05,
    fontFamily: 'Gotham',
    // color: '#ffffff',
    marginTop: '1vh',
    pointerEvents: 'none',
  },
})
