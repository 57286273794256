// import './../../App.css'
import './App.css'
import { Canvas } from '@react-three/fiber'
import WineMapLogic from './core/WineMapLogic'
import { usePage, useWine, useLoading } from './stores'
import ScrollManager from './core/ScrollManager'
import UIManager from './core/UIManager'
import LoadingScreen from './UI/LoadingScreen'
import DeviceCompatibility from './UI/DeviceCompatibility'
import { activateGA } from '../../helper/google-analytics'
import { gaConfig } from './constants/helper-functions'
// import { useEffect } from 'react'

activateGA(gaConfig.code)

function App() {
  const { wineMapping } = useWine()
  const loader = useLoading()
  const page = usePage()
  // let XR8
  // const { camera, scene } = XR8.Threejs.xrScene()
  // useEffect(() => {
  //   console.log(camera)
  //   console.log(scene)
  // }, [])
  return (
    <>
      {(page.currentPage === 'Wine3D' || loader.wineLoading) && (
        <Canvas
          // concurrent
          legacy
          dpr={[1, 1.5]}
          // pixelRatio={2}
          camera={{ position: [0, 0, 10], far: 10000, near: 0.1, fov: 75 }}
          id="canvas"
          // shadowMap
          // events={true}
          shadows
          gl={{ alpha: false, antialias: true }}
          // onCreated={({ gl }) => gl.setClearColor('#ffffff')}
        >
          <hemisphereLight color="#ffffff" groundColor="#ffffff" intensity={3.5} />
          <ScrollManager />
          <LoadingScreen />
        </Canvas>
      )}

      <DeviceCompatibility />
      <UIManager />

      {wineMapping && <WineMapLogic />}
    </>
  )
}

export default App
