import React, { useEffect, useState } from 'react'
import { useControls, useLoading, usePage, useQuestion, useScrolling, useWine } from '../stores'
import { css, StyleSheet } from 'aphrodite'
import { diorama, wineDetails } from '../constants/wine-details'
// import { isAndroid, isIOS } from 'react-device-detect'
import { share_image } from '../constants/share-image-logic'
import { sendEvent } from '../../../helper/google-analytics'

function ConclusionUI() {
  const controls = useControls()
  const scrollStore = useScrolling()
  const { setWineId, wineId } = useWine()
  const loader = useLoading()
  const page = usePage()
  const dioramaModel = useQuestion()
  const [shareImage, setShareImage] = useState(null)

  const wineid = wineDetails.filter((id) => {
    return id.wineId == wineId
  })

  // const AndriodUrl = wineid.map((url) => {
  //   return url.andriodAR
  //   // isIOS && url.iOSAR
  // })
  // const IOSUrl = wineid.map((url) => {
  //   return url.iOSAR
  //   // isIOS && url.iOSAR
  // })

  // const AndroidIntent =
  //   'intent://arvr.google.com/scene-viewer/1.2?file=' +
  //   'https://experiencejacobscreek.com/' +
  //   AndriodUrl +
  //   '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'

  // const IOSIntent = 'https://experiencejacobscreek.com/' + IOSUrl

  const wineName = wineid.map((name) => {
    return name.wineName
  })

  const conclusionTitle = wineid.map((title) => {
    return title.conclusionTitle
  })

  const dioramaName = diorama.map((id) => {
    if (dioramaModel.dioramaId == id.diorama_id) {
      return id.dioramaName
    }
  })

  function ResetScroll() {
    sendEvent('Navigation', 'Scroll', 'Top')
    scrollStore.setResetScroll(true)
    controls.setOrbitControls(false)
  }

  function RetryApp() {
    sendEvent('Navigation', 'Click', 'Retry')
    controls.setOrbitControls(false)
    // setWine3D(false)
    setWineId(-1)
    dioramaModel.setDioramaId(-1)
    page.setCurrentPage('LogoLoading')
    loader.setLogoLoading(true)
    page.setRetryExp(true)
  }

  useEffect(() => {
    if (shareImage != null) {
      console.log('share image: ' + shareImage)
      fetch(shareImage)
        .then(function (response) {
          console.log('blob: ' + response)
          return response.blob()
        })
        .then(function (blob) {
          var file = new File([blob], 'picture.jpg', { type: 'image/jpeg' })
          var filesArray = [file]
          let shareData = {
            title: `Jacob's Creek Wine Experience`,
            text: `I just picked my wine in an amazing Augmented Reality experience! You should try it too.\n`,
            url: `https://experiencejacobscreek.com/`,
            files: filesArray,
          }
          if (navigator.share) {
            navigator
              .share(shareData)
              .then(() => console.log('Shared Successfully'))
              .catch((e) => console.log(e))
          } else {
            console.log('navigator false')
          }
        })
    }
  }, [shareImage])

  function ShareExp() {
    sendEvent('Outgoing', 'Click', 'Share')
    share_image(
      dioramaName.toString().replace(/,/g, ''),
      wineName.toString().replace(/,/g, ''),
      setShareImage
    )
  }

  return (
    <>
      {controls.orbitControls && (
        <>
          <div className={css(styles.backButtonContainer)}>
            <button className={css(styles.backButton)} onClick={ResetScroll}>
              <img
                className={css(styles.IconStyle)}
                src="./images/jc/icons/BackUp.png"
                alt="Go Back"
              />
            </button>
          </div>
          <div className={css(styles.title)}>{conclusionTitle}</div>
          <div className={css(styles.bottomContainer)}>
            {/* AR Button */}
            {/* {isAndroid && (
              <button
                className={css(styles.arButton)}
                onClick={() => {
                  window.open(AndroidIntent)
                  sendEvent('Outgoing', 'Click', 'AR')
                  console.log('intent: ' + AndroidIntent)
                }}
              >
                <img className={css(styles.IconStyle)} src="./images/jc/icons/ar.png" alt="AR" />
              </button>
            )}
            {isIOS && (
              <a rel="ar" className={css(styles.arLink)} href={IOSIntent}>
                <img
                  src="./images/jc/icons/ar.png"
                  width={30}
                  alt="AR ICON"
                  className={css(styles.IconStyle)}
                />
              </a>
            )} */}
            <div className={css(styles.text)}>Pinch and Zoom to rotate</div>
            {/* Retry Button */}
            <button className={css(styles.retryButton)} onClick={() => RetryApp()}>
              Tap to Retry
            </button>
            <div className={css(styles.text, styles.textStyle)}>
              Share your love for Jacob&apos;s Creek
              <br />
              with a simple click.
            </div>

            {/* Share Button */}
            <button className={css(styles.arButton, styles.buttonStyle)} onClick={ShareExp}>
              <img
                className={css(styles.IconStyle)}
                src="./images/jc/icons/Share.png"
                alt="Share"
              />
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default ConclusionUI

const styles = StyleSheet.create({
  backButtonContainer: {
    position: 'absolute',
    top: '2vh',
    right: '1vh',
  },
  backButton: {
    background: 'none',
    border: 'none',
    outline: 0,
    height: 'auto',
    width: '6.5vh',
    overflow: 'hidden',
  },
  title: {
    position: 'absolute',
    top: '14vh',
    left: '4vw',
    right: '4vw',
    textAlign: 'center',
    fontFamily: 'Gotham',
    fontSize: '1.8vh',
    color: 'white',
    letterSpacing: 2,
  },
  bottomContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // zIndex: 2,
  },
  arButton: {
    outline: 0,
    border: 'none',
    width: '20vh',
    height: 'auto',
    marginBottom: '2vh',
    pointerEvents: 'auto',
    background: 'none',
  },
  arLink: {
    width: '18vh',
    marginBottom: '2vh',
    height: 'auto',
  },
  IconStyle: {
    height: '100%',
    width: '100%',
  },
  retryButton: {
    outline: 0,
    border: 'none',
    width: '23vh',
    padding: '1.1vh',
    backgroundColor: '#ffffff',
    pointerEvents: 'auto',
    fontSize: '2.3vh',
    fontFamily: 'Gotham',
    letterSpacing: 1,
    whiteSpace: 'nowrap',
    color: '#000000',
    textTransform: 'uppercase',
    marginBottom: '2vh',
  },
  text: {
    fontSize: '1.8vh',
    fontFamily: 'Gotham',
    color: '#ffffff',
    marginBottom: '3vh',
    letterSpacing: 2,
  },
  textStyle: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '2vh',
  },
  buttonStyle: {
    width: '6.5vh',
    marginBottom: '0vh',
  },
})
