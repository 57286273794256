/**
 //! Wine 
1,1 - Riesling (6)
1,2 - Chardonnay (3)
1,3 - CV Tempranillo (11) // ask Vishal
1,4 - CV Blanco (10)
1,5 - Cabernet Sauvignon (2)
1,6 - Shiraz Cabernet (1)

2,1 - Chardonnay Pinot Noir (7) // ask Vishal
2,2 - Chardonnay (3)
2,3 - BE Pinot Noir (8) (Check material info)
2,4 - Sauvignon Blanc (9) (Check material info)
2,5 - Cabernet Sauvignon (2)
2,6 - Double Barrel (5)

3,1 - Riesling (6)
3,2 - Chardonnay (3)
3,3 - Merlot (4)
3,4 - CV Blanco (10)
3,5 - JC Shiraz Cabernet (1) // vishal input
3.6 - Cabernet Sauvignon (2) // change this

4,1 - Chardonnay Pinot Noir (7) // vishal input
4,2 - CV Blanco (10)
4,3 - BE Pinot Noir (8)
4,4 - BE Sauvignon Blanc (9)
4,5 - CV Tempranillo (11)
4,6 - JC Double Barrel (5)

5,1 - Chardonnay Pinot Noir (7) // vishal input
5,2 - CV blanco (10)
5,3 - BE Pinot Noir (8)
5,4 - BE Sauvignon Blanc (9)
5,5 - CV Tempranillo (11)
5,6 - JC Double Barrel (5)

//! Diorama
1 - SundayAfternoon
2 - RomanticDinner
3 - MovieNight
4 - FestiveDinner
 */

export const WineMap = [
  {
    setA_id: 1,
    diorama_id: 1,
    setB: [
      { setB_id: 1, wine_id: 6 },
      { setB_id: 2, wine_id: 3 },
      { setB_id: 3, wine_id: 11 },
      { setB_id: 4, wine_id: 10 },
      { setB_id: 5, wine_id: 2 },
      { setB_id: 6, wine_id: 1 },
    ],
  },
  {
    setA_id: 2,
    diorama_id: 2,
    setB: [
      { setB_id: 1, wine_id: 7 },
      { setB_id: 2, wine_id: 3 },
      { setB_id: 3, wine_id: 8 },
      { setB_id: 4, wine_id: 9 },
      { setB_id: 5, wine_id: 2 },
      { setB_id: 6, wine_id: 5 },
    ],
  },
  {
    setA_id: 3,
    diorama_id: 3,
    setB: [
      { setB_id: 1, wine_id: 6 },
      { setB_id: 2, wine_id: 3 },
      { setB_id: 3, wine_id: 4 },
      { setB_id: 4, wine_id: 10 },
      { setB_id: 5, wine_id: 1 },
      { setB_id: 6, wine_id: 2 },
    ],
  },
  {
    setA_id: 4,
    diorama_id: 4,
    setB: [
      { setB_id: 1, wine_id: 7 },
      { setB_id: 2, wine_id: 10 },
      { setB_id: 3, wine_id: 8 },
      { setB_id: 4, wine_id: 9 },
      { setB_id: 5, wine_id: 11 },
      { setB_id: 6, wine_id: 5 },
    ],
  },
  {
    setA_id: 5,
    diorama_id: 4,
    setB: [
      { setB_id: 1, wine_id: 7 },
      { setB_id: 2, wine_id: 10 },
      { setB_id: 3, wine_id: 8 },
      { setB_id: 4, wine_id: 9 },
      { setB_id: 5, wine_id: 11 },
      { setB_id: 6, wine_id: 5 },
    ],
  },
]
