import * as THREE from 'three'

export function WineMaterial(wineRef, material, wineName, bottleTexture) {
  // let TasteCard_PN = wineRef === 'TasteCard_PN'
  if (wineName === 'Cabernet Sauvignon' || wineName === 'Merlot') {
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    } else if (wineRef === 'Bottle') {
      material.side = THREE.DoubleSide
      bottleTexture.flipY = false
      material.map = bottleTexture
      material.emissiveMap = bottleTexture
      //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
      material.emissiveIntensity = 1
      material.metalness = 0.51
      material.roughness = 0.269
      material.envMapIntensity = 2
    } else if (wineRef === 'Glass') {
      material.side = THREE.FrontSide
      material.color = new THREE.Color(0x0d0402)
      material.envMapIntensity = 0.04
      material.roughness = 0.09
      material.metalness = 1
      material.transparent = true
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0x750000)
      material.side = THREE.FrontSide
      material.metalness = 1
      material.roughness = 0.2
      material.transparent = true
      material.opacity = 0.5
    } else if (wineRef === 'WineGlass') {
      material.color = new THREE.Color('#ffffff')
      material.side = THREE.FrontSide
      material.roughness = 0.1
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.64
    }
  } else if (wineName === 'Chardonnay') {
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    } else if (wineRef === 'Bottle') {
      material.side = THREE.DoubleSide
      bottleTexture.flipY = false
      material.map = bottleTexture
      material.emissiveMap = bottleTexture
      //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
      material.emissiveIntensity = 1
      material.metalness = 0.58
      material.roughness = 0.06
      material.envMapIntensity = 0.6
    } else if (wineRef === 'Glass') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0.2
      material.roughness = 0
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.5
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0x927203)
      material.side = THREE.FrontSide
      material.metalness = 0
      material.roughness = 0.2
      material.transparent = true
      material.opacity = 0.2
    } else if (wineRef === 'WineGlass') {
      material.side = THREE.FrontSide
      material.roughness = 0.1
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.2
    }
  } else if (wineName === 'Chardonnay_Pinot Noir') {
    //! Add this line later
    // wine.nodes.base_6.geometry.material = MeshStandardMaterial
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    } else if (wineRef === 'Bottle') {
      material.side = THREE.DoubleSide
      bottleTexture.flipY = false
      material.map = bottleTexture
      material.emissiveMap = bottleTexture
      //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
      material.emissiveIntensity = 1
      material.metalness = 0.58
      material.roughness = 0.06
      material.envMapIntensity = 0.6
    } else if (wineRef === 'Glass') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0.2
      material.roughness = 0
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.5
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0xfee217)
      material.emissive = new THREE.Color(0xa95807)
      material.emissiveIntensity = 1
      material.side = THREE.FrontSide
      material.metalness = 1
      material.roughness = 0.5
      material.transparent = true
      material.opacity = 0.2
    } else if (wineRef === 'WineGlass') {
      material.side = THREE.FrontSide
      material.roughness = 0
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.2
    }
  } else if (wineName === 'Shiraz') {
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    } else if (wineRef === 'Bottle') {
      material.side = THREE.DoubleSide
      bottleTexture.flipY = false
      material.map = bottleTexture
      material.emissiveMap = bottleTexture
      //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
      material.emissiveIntensity = 1
      material.metalness = 0.51
      material.roughness = 0.269
      material.envMapIntensity = 2
    } else if (wineRef === 'Glass') {
      material.side = THREE.FrontSide
      material.color = new THREE.Color(0x0d0402)
      material.envMapIntensity = 0.04
      material.roughness = 0.09
      material.metalness = 1
      material.transparent = true
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0x750000)
      material.side = THREE.FrontSide
      material.metalness = 1
      material.roughness = 0.2
      material.transparent = true
      material.opacity = 0.5
    } else if (wineRef === 'WineGlass') {
      material.color = new THREE.Color('#ffffff')
      material.side = THREE.FrontSide
      material.roughness = 0.1
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.64
    }
  } else if (wineName === 'DoubleBarrel') {
    material.emissiveIntensity = 1
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    } else if (wineRef === 'Bottle') {
      material.side = THREE.FrontSide
      bottleTexture.flipY = false
      material.map = bottleTexture
      material.emissiveMap = bottleTexture
      //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
      material.emissiveIntensity = 1
      material.metalness = 0.58
      material.roughness = 0.06
      material.envMapIntensity = 0.6
    } else if (wineRef === 'Glass') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0.04
      material.roughness = 0.09
      material.metalness = 1
      material.transparent = false
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0x150000)
      material.side = THREE.FrontSide
      material.metalness = 1
      material.roughness = 0.2
      material.transparent = true
      material.opacity = 0.5
    } else if (wineRef === 'WineGlass') {
      material.side = THREE.FrontSide
      material.roughness = 0.1
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.33
      material.envMapIntensity = 0.2
    }
  } else if (wineName === 'Riesling') {
    if (wineRef === 'TasteCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'FoodCard_PN') {
      material.side = THREE.FrontSide
      material.envMapIntensity = 0
    } else if (wineRef === 'Condiments_Day') {
      material.envMapIntensity = 0
      material.side = THREE.FrontSide
    }
    // else if (wineRef === 'Bottle') {
    //   material.side = THREE.FrontSide
    //   bottleTexture.flipY = false
    //   material.map = bottleTexture
    //   material.emissiveMap = bottleTexture
    //   //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    //   material.emissiveIntensity = 1
    //   material.metalness = 0.51
    //   material.roughness = 0.269
    //   material.envMapIntensity = 2
    // }
    else if (wineRef === 'Glass') {
      material.side = THREE.DoubleSide
      material.color = new THREE.Color(0x309181)
      // material.envMapIntensity = 0.04
      // material.roughness = 0.2
      // material.metalness = 0.6
      material.emissive = new THREE.Color(0x181806)
      // material.envMapIntensity = 0.3 497a00
      material.roughness = 0.1
      material.metalness = 0.8
      material.transparent = true
      // material.transparent = true
      material.opacity = 0.5
      material.depthTest = true
      material.depthWrite = true
      // material.blending = THREE.MultiplyBlending
    } else if (wineRef === 'Liquid') {
      material.color = new THREE.Color(0x927203)
      material.side = THREE.DoubleSide
      material.metalness = 0.8
      material.roughness = 0.2
      material.transparent = true
      material.opacity = 0.5
      material.depthTest = true
      material.depthWrite = true
    } else if (wineRef === 'WineGlass') {
      material.side = THREE.FrontSide
      material.roughness = 0.1
      material.metalness = 1
      material.transparent = true
      material.opacity = 0.2
    } else if (wineRef === 'Lable') {
      material.color = new THREE.Color('#ffffff')
      material.side = THREE.FrontSide
      material.roughness = 0
      material.metalness = 0.1
      material.emissive = new THREE.Color(0x000000)
      // material.transparent = true
      // material.opacity = 0.64
    }
  } else if (wineName === 'Estate_Pinot_Noir') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.2
    material.Bottle.roughness = 0.5
    material.Bottle.envMapIntensity = 2

    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x0d0402)
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = true

    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true

    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
    // material.transparent = true
    // material.opacity = 0.64
  } else if (wineName === 'Sauvigion Blanc') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    // material.side = THREE.FrontSide
    // bottleTexture.flipY = false
    // material.map = bottleTexture
    // material.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    // material.emissiveIntensity = 0.2
    material.Bottle.metalness = 0.05
    material.Bottle.roughness = 0.06
    // material.envMapIntensity = 0.6

    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.2
    material.Glass.roughness = 0
    material.Glass.metalness = 1
    material.Glass.transparent = true
    material.Glass.opacity = 0.8
    material.Glass.color = new THREE.Color(0xbcd030)

    material.Liquid.color = new THREE.Color(0x93a314)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 0.5
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5

    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
  } else if (wineName === 'Viejo Blanco') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    // material.side = THREE.FrontSide
    // bottleTexture.flipY = false
    // material.map = bottleTexture
    // material.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.2
    material.Bottle.roughness = 0.06
    // material.envMapIntensity = 0.6

    material.Glass.color = new THREE.Color(0xffd91a)
    material.Glass.emissive = new THREE.Color(0x1e2315)
    // material.Glass.emissiveIntensity = 1
    material.Glass.side = THREE.FrontSide
    material.envMapIntensity = 0.2
    material.Glass.roughness = 0.2
    material.Glass.metalness = 0.5
    material.Glass.transparent = true
    material.Glass.opacity = 0.9
    material.Glass.depthTest = true
    material.Glass.depthWrite = true

    material.Liquid.color = new THREE.Color(0xfee217)
    material.Liquid.emissive = new THREE.Color(0xa95807)
    material.Liquid.emissiveIntensity = 1
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.5
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.2

    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
  } else if (wineName === 'Viejo Tempranillo') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x0d0402)
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = true

    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5

    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
  }
}

export function DioramaMaterial() {
  // const roughnessMipMapper = new RoughnessMipmapper(gl)
  // material.emissiveMap = material.map
  // material.emissive = new THREE.Color('#ffffff')
  // material.envMapIntensity = 0
  // material.emissiveIntensity = 1
  // roughnessMipMapper.generateMipmaps(material)
  // roughnessMipMapper.dispose()
}

export function MaterialInfo(material, wineName, bottleTexture) {
  if (wineName === 'Riesling') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    material.Glass.side = THREE.DoubleSide
    material.Glass.color = new THREE.Color(0xc0ded3)
    material.Glass.roughness = 0.2
    material.Glass.metalness = 0.95
    material.Glass.transparent = true
    material.Glass.opacity = 0.85
    material.Glass.emissiveIntensity = 0

    // material.Lable.color = new THREE.Color('#ffffff')
    // material.Lable.side = THREE.FrontSide
    // material.Lable.roughness = 0.8
    // material.Lable.metalness = 0.1
    // material.Lable.emissive = new THREE.Color(0x000000)

    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
    material.WineGlass.emissiveIntensity = 0

    material.Liquid.side = THREE.FrontSide
    material.Liquid.color = new THREE.Color(0xfee217)
    material.Liquid.emissive = new THREE.Color(0xa95807)
    material.Liquid.emissiveIntensity = 1
    material.Liquid.roughness = 0.5
    material.Liquid.metalness = 1
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.2
  } else if (wineName === 'Chardonnay_Pinot Noir') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.Bottle.side = THREE.FrontSide
    bottleTexture.flipY = false
    material.Bottle.map = bottleTexture
    material.Bottle.emissiveMap = bottleTexture
    //material.Bottle.emissive = new THREE.Color('#ffffff');
    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.58
    material.Bottle.roughness = 0.06
    material.Bottle.envMapIntensity = 0.6
    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.2
    material.Glass.roughness = 0
    material.Glass.metalness = 1
    material.Glass.transparent = true
    material.Glass.opacity = 0.5
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0
    material.WineGlass.metalness = 1
    //material.WineGlass.reflectivity = 1;
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
    //material.WineGlass.emissive = new THREE.Color(0x000000);
    material.WineGlass.emissiveIntensity = 0
    // wine.nodes.base_6.geometry.material = MeshStandardMaterial
    material.Liquid.side = THREE.FrontSide
    material.Liquid.color = new THREE.Color(0xfee217)
    material.Liquid.emissive = new THREE.Color(0xa95807)
    material.Liquid.emissiveIntensity = 1
    material.Liquid.roughness = 0.5
    material.Liquid.metalness = 1
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.2
    //material.Liquid.vertexColors = true
  } else if (wineName === 'Chardonnay') {
    console.log(material)
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.WineGlass.side = THREE.FrontSide
    //wine.materials.WineGlass.envMapIntensity = 0.2;
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
    material.Lable.side = THREE.FrontSide
    bottleTexture.flipY = false
    material.Lable.map = bottleTexture
    material.Lable.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color(''#ffffff'');
    material.Lable.emissiveIntensity = 1
    material.Lable.metalness = 0.58
    material.Lable.roughness = 0.06
    material.Lable.envMapIntensity = 0.6
    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.2
    material.Glass.roughness = 0
    material.Glass.metalness = 1
    material.Glass.transparent = true
    material.Glass.opacity = 0.5
    material.Liquid.color = new THREE.Color(0x927203)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 0
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.2
  } else if (wineName === 'DoubleBarrel') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.envMapIntensity = 0.2
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.33
    material.Bottle.side = THREE.FrontSide
    bottleTexture.flipY = false
    material.Bottle.map = bottleTexture
    material.Bottle.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color(''#ffffff'');
    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.58
    material.Bottle.roughness = 0.06
    material.Bottle.envMapIntensity = 0.6
    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = false
    material.Liquid.color = new THREE.Color(0x150000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5
    material.emissiveIntensity = 1
  } else if (wineName === 'Shiraz') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.Lable.side = THREE.FrontSide
    // bottleTexture.flipY = false
    // material.Lable.map = bottleTexture
    // material.Lable.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    // material.Lable.emissiveIntensity = 1
    // material.Lable.metalness = 0.51
    // material.Lable.roughness = 0.269
    // material.Lable.envMapIntensity = 2
    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x0d0402)
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = true
    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
    //material.WineGlass.emissiveIntensity = 0;
    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5
  } else if (wineName === 'CabernetSauvignon') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.Bottle.side = THREE.FrontSide
    bottleTexture.flipY = false
    material.Bottle.map = bottleTexture
    material.Bottle.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.51
    material.Bottle.roughness = 0.269
    material.Bottle.envMapIntensity = 2
    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x0d0402)
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = true
    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
    //material.WineGlass.emissiveIntensity = 0;
    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5
  } else if (wineName === 'Merlot') {
    console.log(material)
    material.TasteCard.side = THREE.FrontSide
    material.TasteCard.envMapIntensity = 0
    material.FoodCard.side = THREE.FrontSide
    material.FoodCard.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.envMapIntensity = 0.2
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.33
    material.Lable.side = THREE.FrontSide
    bottleTexture.flipY = false
    // material.Lable.map = bottleTexture
    // material.Lable.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color(''#ffffff'');
    // material.Bottle.emissiveIntensity = 1
    // material.Lable.metalness = 0.58
    // material.Lable.roughness = 0.06
    // material.Bottle.envMapIntensity = 0.6
    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.04
    material.Glass.roughness = 0.09
    material.Glass.metalness = 1
    material.Glass.transparent = false
    material.Liquid.color = new THREE.Color(0x150000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5
    material.emissiveIntensity = 1
  } else if (wineName === 'Estate_Pinot_Noir') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0
    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0
    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.2
    material.Bottle.roughness = 0.5
    material.Bottle.envMapIntensity = 2

    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x180c02)
    material.Glass.envMapIntensity = 0.8
    material.Glass.roughness = 0
    material.Glass.metalness = 0.9
    material.Glass.opacity = 0.8
    material.Glass.transparent = true

    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true

    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
    // material.transparent = true
    // material.opacity = 0.64
  } else if (wineName === 'Sauvigion Blanc') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    // material.side = THREE.FrontSide
    // bottleTexture.flipY = false
    // material.map = bottleTexture
    // material.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    // material.emissiveIntensity = 0.2
    material.Bottle.metalness = 0.05
    material.Bottle.roughness = 0.06
    // material.envMapIntensity = 0.6

    // material.Glass.side = THREE.FrontSide
    // material.Glass.envMapIntensity = 0.2
    // material.Glass.roughness = 0
    // material.Glass.metalness = 1
    // material.Glass.transparent = true
    // material.Glass.opacity = 0.8
    // material.Glass.color = new THREE.Color(0xbcd030)

    material.Glass.side = THREE.FrontSide
    material.Glass.envMapIntensity = 0.2
    material.Glass.roughness = 0
    material.Glass.metalness = 0.8
    material.Glass.transparent = true
    material.Glass.opacity = 0.85
    material.Glass.color = new THREE.Color(0x415511)

    material.Liquid.color = new THREE.Color(0x93a314)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 0.5
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5

    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
  } else if (wineName === 'Viejo Blanco') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    // material.side = THREE.FrontSide
    // bottleTexture.flipY = false
    // material.map = bottleTexture
    // material.emissiveMap = bottleTexture
    //wine.materials.Bottle.emissive = new THREE.Color('#ffffff');
    material.Bottle.emissiveIntensity = 1
    material.Bottle.metalness = 0.2
    material.Bottle.roughness = 0.06
    // material.envMapIntensity = 0.6

    material.Glass.color = new THREE.Color(0x908047)
    material.Glass.emissive = new THREE.Color(0x000000)
    // material.Glass.emissiveIntensity = 1
    material.Glass.side = THREE.FrontSide
    material.envMapIntensity = 0.2
    material.Glass.roughness = 0
    material.Glass.metalness = 0.8
    material.Glass.transparent = true
    material.Glass.opacity = 0.9
    material.Glass.depthTest = true
    material.Glass.depthWrite = true

    material.Liquid.color = new THREE.Color(0xfee217)
    material.Liquid.emissive = new THREE.Color(0xa95807)
    material.Liquid.emissiveIntensity = 1
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.5
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.2

    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.2
  } else if (wineName === 'Viejo Tempranillo') {
    material.TasteCard_PN.side = THREE.FrontSide
    material.TasteCard_PN.envMapIntensity = 0

    material.FoodCard_PN.side = THREE.FrontSide
    material.FoodCard_PN.envMapIntensity = 0

    material.Condiments_Day.envMapIntensity = 0
    material.Condiments_Day.side = THREE.FrontSide

    material.Glass.side = THREE.FrontSide
    material.Glass.color = new THREE.Color(0x180c02)
    material.Glass.envMapIntensity = 0.8
    material.Glass.roughness = 0
    material.Glass.metalness = 0.9
    material.Glass.opacity = 0.8
    material.Glass.transparent = true

    material.Liquid.color = new THREE.Color(0x750000)
    material.Liquid.side = THREE.FrontSide
    material.Liquid.metalness = 1
    material.Liquid.roughness = 0.2
    material.Liquid.transparent = true
    material.Liquid.opacity = 0.5

    material.WineGlass.color = new THREE.Color('#ffffff')
    material.WineGlass.side = THREE.FrontSide
    material.WineGlass.roughness = 0.1
    material.WineGlass.metalness = 1
    material.WineGlass.transparent = true
    material.WineGlass.opacity = 0.64
  }
}
