import React from 'react'
import Branding from '../UI/Branding'
import ConclusionUI from '../UI/ConclusionUI'
import LandingPage from '../UI/LandingPage'
import LeafLoadingScreen from '../UI/LeafLoadingScreen'
import LogoLoadingScreen from '../UI/LogoLoadingScreen'
import Questionnaire from '../UI/Questionnaire'
import YearValidation from '../UI/YearValidation'
import ScrollIndicator from '../UI/ScollIndicator'
import { usePage } from '../stores'

function UIManager() {
  const page = usePage()

  function displayPage() {
    switch (page.currentPage) {
      case 'YearValidation':
        return <YearValidation />
      case 'LogoLoading':
        return <LogoLoadingScreen />
      case 'Landing':
        return <LandingPage />
      case 'LeafLoading':
        return <LeafLoadingScreen />
      case 'Questionaire':
        return <Questionnaire />
    }
  }

  return (
    <>
      <>{displayPage()}</>
      <Branding />
      <ConclusionUI />
      {/* <LoadingScreen /> */}

      <ScrollIndicator />
    </>
  )
}

export default UIManager
