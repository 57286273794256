import React from 'react'
import { useQuestion } from '../stores'
import Background from './Background'
import QueBackButton from './QueBackButton'
import QuestionSet from './QuestionSet'
// import QuestionSetB from './QuestionSetB'

function Questionnaire() {
  const { queSetB } = useQuestion()
  return (
    <>
      <Background />
      <QuestionSet />
      {queSetB && <QueBackButton />}

      {/* <QuestionSetB /> */}
    </>
  )
}

export default Questionnaire
