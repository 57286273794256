import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLoading, usePage } from '../stores'
import { a as web } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { Html } from '@react-three/drei'

function LoadingScreen() {
  const page = usePage()
  const loader = useLoading()
  // const { setWine3D } = useWine()

  useEffect(() => {
    if (loader.wineLoading) {
      // setWine3D(true)
      page.setCurrentPage('Wine3D')
      setTimeout(() => {
        loader.setWineLoading(false)
      }, 5000)
    }
  }, [loader.wineLoading])

  const fadeAnim = useSpring({
    opacity: loader.wineLoading ? 1 : 0,
    // zIndex: wine3D ? 18000 : 1,
  })

  return (
    <>
      {loader.wineLoading && (
        <Html fullscreen>
          <web.div className={css(styles.wrapper)} style={fadeAnim}>
            <div className={css(styles.gif)}>
              <img src="./images/jc/gif/Loading-2.gif" alt="loading" loop="infinite" />
            </div>
            <div className={css(styles.LoadingTextHolder)}>
              <div className={css(styles.SupportText)}>
                The best wines take a few years to make, this will only take a few seconds.
              </div>
              <div className={css(styles.SupportText1)}>DRINK RESPONSIBLY</div>
            </div>
          </web.div>
        </Html>
      )}
    </>
  )
}

export default LoadingScreen

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    textAlign: 'center',
    // zIndex: 1,
  },
  gif: {
    paddingTop: '20%',
    height: '40%',
    textAlign: 'center',
  },
  LoadingText: {
    display: 'none',
  },
  LoadingTextHolder: {
    paddingTop: '15%',
    height: '60%',
    textAlign: 'center',
  },
  SupportText: {
    height: '30%',
    color: 'black',
    fontFamily: 'Gotham',
    fontSize: '2vh',
    lineHeight: '130%',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  SupportText1: {
    height: '30%',
    color: 'black',
    fontFamily: 'Gotham',
    fontSize: '2.3vh',
    letterSpacing: '1px',
  },
})
