import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useThree, useFrame } from '@react-three/fiber'
import { useGLTF, useScroll } from '@react-three/drei'

import { useControls, useScrolling, useWine } from '../stores'
import {
  GetScrollState,
  map_range,
  ModelLerp,
  points,
  points1,
} from '../constants/helper-functions'
// import { WineMaterial } from '../constants/model-material-info'
import { MaterialInfo } from '../constants/model-material-info'
import { useTexture } from '@react-three/drei'

function WineBottle({ url, dioramaUrl, dioramaName, wineName, bottleTxt }) {
  const { scene, nodes, materials } = useGLTF(url)
  const dioramaModel = useGLTF(dioramaUrl)
  const bottle = useRef()
  const diorama = useRef()
  const { camera } = useThree()
  const [loaded, setLoaded] = useState(false)
  const scroll = useScroll()
  const controls = useControls()
  const scrollStore = useScrolling()
  const scrollInd = useWine()
  const bottleTexture = useTexture(bottleTxt)

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      camera.near = 100
      camera.fov = 35
      camera.updateProjectionMatrix()
    }
  }, [camera, loaded, setLoaded])

  useLayoutEffect(() => {
    // console.log(materials)
    MaterialInfo(materials, wineName, bottleTexture)
    // WineMaterial(nodes.material.name, nodes.material, wineName, bottleTexture)
  }, [scene, nodes, materials, bottleTexture, wineName])

  useFrame(() => {
    if (loaded) {
      //! Fix reset scroll
      let value = scroll.offset * 10
      if (scrollStore.resetScroll) {
        scroll.el.scrollTop = scroll.offset
        if (scroll.offset > 0.0000001 && scrollStore.resetScroll) {
          scroll.scroll.current -= 0.01
        } else {
          scroll.scroll.current = 0
          scroll.el.scrollTop = 0
          scroll.el.style.touchAction = 'auto'
          scroll.offset = 0
          scrollStore.setResetScroll(false)
        }
      }

      if (value < 0.5 && !scrollInd.scrollIndicator) {
        scrollInd.setScrollIndicator(true)
      } else {
        if (value > 0.5 && scrollInd.scrollIndicator) {
          scrollInd.setScrollIndicator(false)
        }
      }

      if (value <= 6.999) {
        if (controls.orbitControls) {
          controls.setOrbitControls(false)
        }

        switch (GetScrollState(value)) {
          case 'Introduce':
            var pointSelection = parseInt(map_range(value, 0, 3, 1, 1000))
            camera.position.x = points[pointSelection].x
            camera.position.y = points[pointSelection].y
            camera.position.z = points[pointSelection].z
            camera.lookAt(0, 0, 0)
            break
          case 'Diorama':
            var pointSelection1 = parseInt(map_range(value, 3, 6.9, 1, 3000))
            camera.position.x = points1[pointSelection1].x
            camera.position.y = points1[pointSelection1].y
            camera.position.z = points1[pointSelection1].z
            camera.lookAt(0, 0, 0)
            break
          default:
        }
      } else {
        if (!controls.orbitControls) {
          controls.setOrbitControls(true)
        }
      }

      ModelLerp(dioramaName, bottle.current, diorama.current, value)
    }
  })
  return (
    <>
      {/* Wine model */}
      <group
        ref={bottle}
        dispose={null}
        scale={[10, 10, 10]}
        position={[0.4, 0.5, 0]}
        rotation={[0, -1.4, 0]}
      >
        <primitive object={scene} />
      </group>
      {/* Diorama model */}
      <group ref={diorama} dispose={null} position={[0, -1400, 0]} scale={[125, 125, 125]}>
        <primitive object={dioramaModel.scene} />
      </group>
    </>
  )
}

export default WineBottle

WineBottle.propTypes = {
  url: PropTypes.string,
  dioramaUrl: PropTypes.string,
  dioramaName: PropTypes.string,
  onReflow: PropTypes.func,
  setOrbitControls: PropTypes.func,
  content: PropTypes.array,
  wineName: PropTypes.string,
  bottleTxt: PropTypes.string,
}
