import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { usePage, useQuestion, useWine } from '../stores'
import { QueContent } from '../constants/questions-content'
import { fadeIn, fadeOut } from 'react-animations'
import { sendEvent } from '../../../helper/google-analytics'

function QuestionSet() {
  const { setQueSetA, setQueSetB, setQueAId, queSetA, setQueBId, queSetB } = useQuestion()
  const { setWineMapping } = useWine()
  const question = useQuestion()
  const [render, setRender] = useState(false)
  const page = usePage()

  function nextQuestionSet() {
    if (queSetA) {
      setQueSetA(false)
      setQueSetB(true)
    } else if (queSetB) {
      setQueSetB(false)
      setWineMapping(true)
      question.setOptionUI(false)
    }
  }

  useEffect(() => {
    if (page.currentPage === 'Questionaire') {
      setRender(true)
    }
  }, [page.currentPage === 'Questionaire'])

  const onAnimationEnd = () => {
    if (page.currentPage != 'Questionaire') setRender(false)
  }

  return (
    <>
      {render && (
        <div
          className={css(
            styles.container,
            page.currentPage === 'Questionaire' ? styles.fadeInAnim : styles.fadeOutAnim
          )}
          onAnimationEnd={onAnimationEnd}
        >
          <div className={css(styles.tag)}>
            {queSetA ? QueContent.setA.tag : QueContent.setB.tag}
          </div>
          <div className={css(styles.title)}>
            {queSetA ? QueContent.setA.title : QueContent.setB.title}
          </div>
          <div className={css(styles.optionContainer)}>
            {queSetA ? (
              <>
                {QueContent.setA.options.map((options) => {
                  return (
                    <>
                      <button
                        id="setA"
                        className={css(styles.button)}
                        onClick={() => {
                          nextQuestionSet()
                          setQueAId(options.id)
                          sendEvent(
                            'Mood Questionnaire',
                            `Question no : ${options.id}`,
                            `${options.label}`
                          )
                          // ButtonPressed()
                        }}
                        key={options.id}
                      >
                        {options.label}
                      </button>
                    </>
                  )
                })}
              </>
            ) : (
              <>
                {QueContent.setB.options.map((options) => {
                  return (
                    <>
                      <button
                        id="setB"
                        className={css(styles.button)}
                        onClick={() => {
                          nextQuestionSet()
                          setQueBId(options.id)
                          sendEvent(
                            'Eating Questionnaire',
                            `Question no : ${options.id}`,
                            `${options.label}`
                          )
                          // ButtonPressed()
                        }}
                        key={options.id}
                      >
                        {options.label}
                      </button>
                    </>
                  )
                })}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default QuestionSet

const styles = StyleSheet.create({
  fadeInAnim: {
    animationName: fadeIn,
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  fadeOutAnim: {
    animationName: fadeOut,
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
  },
  container: {
    position: 'absolute',
    left: '3vh',
    right: '3vh',
    top: '15vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundColor: 'white',
    zIndex: 2,
    // margin: 20,
  },
  tag: {
    // marginTop: '16vh',
    fontSize: '2.9vh',
    fontFamily: 'Gotham',
    color: '#000000',
    letterSpacing: -0.05,
  },
  title: {
    marginTop: '2.6vh',
    fontSize: '3vh',
    fontFamily: 'Gotham',
    fontWeight: 550,
    color: '#000000',
    letterSpacing: -0.05,
  },
  optionContainer: {
    marginTop: '2vh',
    overflowY: 'auto',
  },
  button: {
    width: '100%',
    height: '9vh',
    // padding: '3.3vh',
    marginTop: '2vh',
    pointerEvents: 'auto',
    // cursor: 'pointer',
    border: 'none',
    outline: 'none',
    fontSize: '1.8vh',
    textTransform: 'uppercase',
    fontFamily: 'Gotham',
    backgroundColor: '#E5E5E5',
    letterSpacing: 0.05,
    ':active': {
      backgroundColor: '#C9C9C9',
      border: 'none',
      textDecoration: 'none',
      outline: 'none',
    },
  },
  // button01: {
  //   ':hover': {
  //     backgroundColor: '#C9C9C9',
  //     border: 'none',
  //     textDecoration: 'none',
  //     outline: 'none',
  //   },
  //   ':focus': {
  //     backgroundColor: '#C9C9C9',
  //     border: 'none',
  //     textDecoration: 'none',
  //     outline: 'none',
  //   },
  // },
})
