import React, { useEffect, useState } from 'react'
// import { isMobile } from 'react-device-detect'
import { StyleSheet, css } from 'aphrodite'
import { sendEvent } from '../../../helper/google-analytics'

function DeviceCompatibility() {
  const [status, setStatus] = useState(false)

  function onResize() {
    let result = isValidScreen()
    setStatus(result)
  }

  function isValidScreen() {
    if (window.innerWidth > window.innerHeight) {
      return true
    } else return false
  }
  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return function cleanup() {
      window.removeEventListener('resize', onResize)
    }
  }, [window])

  useEffect(() => {
    if (status) {
      sendEvent('Error', 'Landing', 'Resolution not Supported')
    }
  }, [status])

  return (
    <>
      {status && (
        <div className={css(styles.wrapper)}>
          <div className={css(styles.LogoContainer)} style={{ zIndex: 2 }}>
            <img
              alt="Logo"
              className={css(styles.Logo)}
              src="./images/jc/branding/JC_updatedLogo.png"
            />
          </div>
          <div className={css(styles.loadingText)}>
            This website is not supported on this screen size yet, please try switching to a Mobile
            Device in Portrait Mode
          </div>
        </div>
      )}
    </>
  )
}

export default DeviceCompatibility

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    height: '100%',
    alignItems: 'center',
    zIndex: 2,
  },
  loadingText: {
    color: 'black',
    // width: '100vw',
    marginTop: '5vh',
    textAlign: 'center',
    fontFamily: 'Gotham',
    fontSize: '2.5vh',
  },
  LogoContainer: {
    width: '30vh',
    height: 'auto',
    textAlign: 'center',
    marginTop: '3vh',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  Logo: {
    width: '100%',
    height: 'auto',
  },
})
