import { OrbitControls, ScrollControls } from '@react-three/drei'
import React, { Suspense } from 'react'
import { diorama, wineDetails } from '../constants/wine-details'
import { useControls, usePage, useQuestion, useWine } from '../stores'
import PropTypes from 'prop-types'
import WineBottle from './WineBottle'
import Skybox from './Skybox'
import WineContent from './WineContent'

function ScrollManager() {
  const { wineId } = useWine()
  const controls = useControls()
  const dioramaModel = useQuestion()
  // const [dioramaName, setDioramaName] = useState(null)
  // const [dioramaUrl, setDioramaUrl] = useState(null)
  const dioramaName = diorama.map((id) => {
    if (dioramaModel.dioramaId == id.diorama_id) {
      return id.dioramaName
    }
  })

  const dioramaUrl = diorama.map((id) => {
    if (dioramaModel.dioramaId == id.diorama_id) {
      return id.dioramaUrl
    }
  })

  // useEffect(() => {
  //   console.log('name: ' + dioramaContent)
  // }, [])
  const page = usePage()

  return (
    <>
      {page.currentPage === 'Wine3D' && (
        <>
          <ScrollControls
            pages={8}
            enabled={controls.orbitControls ? false : true}
            // distance={1} // A factor that increases scroll bar travel (default: 1)
            // damping={10}
          >
            {wineDetails.map((item) => {
              return (
                <>
                  {wineId == item.wineId && (
                    <>
                      {/* {console.log('wine id: ' + wineId + 'item id: ' + item.wineId)} */}
                      <Suspense fallback={null}>
                        <WineBottle
                          url={item.url}
                          dioramaUrl={dioramaUrl.toString().replace(/,/g, '')}
                          dioramaName={dioramaName.toString().replace(/,/g, '')}
                          wineName={item.wineName}
                          bottleTxt={item.bottleTexture}
                        />
                        <WineContent content={item.content} hdr={item.hdr} />
                        <Skybox hdr={item.hdr} />
                      </Suspense>
                    </>
                  )}
                </>
              )
            })}
          </ScrollControls>
          {controls.orbitControls && <OrbitControls />}
        </>
      )}
    </>
  )
}

export default ScrollManager

ScrollManager.propTypes = {
  scrollArea: PropTypes.element,
  onScroll: PropTypes.func,
  onPointerMove: PropTypes.func,
  setPages: PropTypes.func,
}
