// const ar_url = 'https://experiencejacobscreek.com/'

export const wineDetails = [
  // 1. Shiraz Cabernet
  {
    wineId: 1,
    url: './wine/Cabernet/Cabernet.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/Cabernet/LM.png',
    wineName: 'Shiraz',
    andriodAR: './_webar/Cabernet.glb',
    iOSAR: './_webar/Cabernet.usdz',
    conclusionTitle: `Your Jacob's Creek Shiraz Cabernet, in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `It’s time to pour out\nsome Shiraz Cabernet!`,
        subtext:
          'A classic Australian blend of Shiraz and Cabernet Sauvignon, this wine with its soft and approachable style is the perfect match for you.',
      },
      {
        header: 'TASTE',
        subtext:
          'Combining dark cherry and plum with notes of mocha spice, the classic Shiraz Cabernet will hit you in all the right spots.',
      },
      {
        header: 'ENJOY WITH',
        subtext: 'Roasted vegetables, tandoori platters or spicy Indian curries.',
      },
    ],
  },
  // 2. Cabernet Sauvignon
  {
    wineId: 2,
    title: 'Wine 02',
    url: './wine/CabernetSauvignon/CabernetSauvignon.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/CabernetSauvignon/LM.png',
    wineName: 'Cabernet Sauvignon',
    andriodAR: './_webar/CabernetSauvignon.glb',
    iOSAR: './_webar/CabernetSauvignon.usdz',
    conclusionTitle: `Your Jacob's Creek Cabernet Sauvignon, in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `It’s time to pour out\nsome Cabernet Sauvignon! `,
        subtext: `The Cabernet Sauvignon is the right choice for you! A classic single varietal Cabernet Sauvignon bursting with flavors of blackcurrants and blackberries and well-integrated oak.`,
      },
      {
        header: 'TASTE',
        subtext:
          'Savory red berries with a touch of green capsicum and hints of freshly ground pepper accompanied by fleshy tannins and a balanced finish.',
      },
      { header: 'ENJOY WITH', subtext: 'Roasted vegetables, tandoori platters or burgers.' },
    ],
  },
  // 3. Chardonnay
  {
    wineId: 3,
    title: 'Wine 03',
    url: './wine/Chardonnay/Chardonnay.gltf',
    hdr: 'skybox/jc_hdr/spruit_sunrise.hdr',
    bottleTexture: './wine/Chardonnay/LM.png',
    wineName: 'Chardonnay',
    andriodAR: './_webar/Chardonnay.glb',
    iOSAR: './_webar/Chardonnay.usdz',
    conclusionTitle: `Your Jacob's Creek Chardonnay in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `It’s time to pour out\nsome Chardonnay!`,
        subtext:
          'This modern Australian chardonnay is complex, yet easy to drink. It showcases a range of varietal flavours - resulting in an unmatched, refreshing experience.',
      },
      {
        header: 'TASTE',
        subtext:
          'An intense mix of citrus and melon, underpinned by toasty notes, will make your taste buds tremble with excitement.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Seafood, chicken or creamy pasta dishes.`,
      },
    ],
  },
  // 4. Merlot
  {
    wineId: 4,
    title: 'Wine 04',
    url: './wine/Merlot/Merlot.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/Merlot/LM.png',
    wineName: 'Merlot',
    andriodAR: './_webar/Merlot.glb',
    iOSAR: './_webar/Merlot.usdz',
    conclusionTitle: `Your Jacob's Creek Merlot, in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `Our Merlot is the right\npick for you!`,
        subtext:
          'Generous fruit and a savory backbone combined with its soft tannins make it a very food friendly wine.',
      },
      {
        header: 'TASTE',
        subtext:
          'Ripe black fruits with subtle nuances of spice and dried herbs balanced by soft structured tannins and a juicy long finish.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Tomato based pastas, pizzas or barbeque.`,
      },
    ],
  },
  // 5.  DoubleBarrel
  {
    wineId: 5,
    title: 'Wine 04',
    url: './wine/DoubleBarrel/DoubleBarrel.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/DoubleBarrel/LM.jpg',
    wineName: 'DoubleBarrel',
    andriodAR: './_webar/DoubleBarrel.glb',
    iOSAR: './_webar/DoubleBarrel.usdz',
    conclusionTitle: `Your Jacob's Creek Double Barrel, in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `The Double Barrel Shiraz\nis the wine for you.`,
        subtext:
          'Enhancing the full, generous flavours of a classic Barossa Shiraz with careful use of aged Scotch Whisky barrels for finishing results in a triumphant flavour perfect for a special occasion.',
      },
      {
        header: 'TASTE',
        subtext:
          'The intense nose offers a heady mix of cedar, vanilla bean and buttered toast with notes of pecan nut and roast cashew.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Roasted vegetables, tandoori platters or spicy Indian curries.`,
      },
    ],
  },
  // 6. Riesling
  {
    wineId: 6,
    title: 'Wine 04',
    url: './wine/Riesling/Riesling.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/Riesling/LM.jpg',
    wineName: 'Riesling',
    andriodAR: './_webar/Reisling.glb',
    iOSAR: './_webar/Reisling.usdz',
    conclusionTitle: `Your Jacob's Creek Riesling, in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_updatedLogo.png',

    content: [
      {
        header: `Our Classic Riesling is the\nwine for you.`,
        subtext:
          'A pristine and refreshing Riesling with floral notes complemented by bright citrus for a long finish.',
      },
      {
        header: 'TASTE',
        subtext:
          'Excellent weight with persistent citrus on the palate. The finish is chalky and salivating. A pure and elegant expression of Riesling.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Fresh, simply prepared seafood such as kingfish ceviche or grilled garfish. Pairs particularly well with sashimi.`,
      },
    ],
  },
  // 7. Chardonnay Pinot Noir
  {
    wineId: 7,
    title: 'Wine 04',
    url: './wine/PinotNoir/pinotnoir.gltf',
    hdr: 'skybox/jc_hdr/spruit_sunrise.hdr',
    bottleTexture: './wine/PinotNoir/PinotNoirLabel_Lightmap.png',
    wineName: 'Chardonnay_Pinot Noir',
    andriodAR: './_webar/PinotNoir.glb',
    iOSAR: './_webar/PinotNoir.usdz',
    conclusionTitle: `Your Jacob's Creek Sparkling in your world.`,
    brand: 'jacob',
    logo_src: './images/jc/branding/JC_White.png',

    content: [
      {
        header: `Our Sparkling is the\nwine for you.`,
        subtext:
          'The Chardonnay Pinot Noir, with its delicious citrus fruit flavours made perfect with the added sparkle, is perfectly suited to your mood.',
      },
      {
        header: 'TASTE',
        subtext:
          'The refreshing lemon citrus aromas of Chardonnay, in harmony with the red currant characters of Pinot Noir make for a stunningly refreshing experience.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Light nibbles like soft, white cheese and Indian snacks, or just drink it on its own. Make sure it's served chilled.`,
      },
    ],
  },
  // 8. Estate_Pinot_Noir
  {
    wineId: 8,
    url: './wine/Estate_Pinot_Noir/Pinot_Noir.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/Estate_Pinot_Noir/LM.jpg',
    wineName: 'Estate_Pinot_Noir',
    andriodAR: './_webar/BEPinotNoir.glb',
    iOSAR: './_webar/BEPinotNoir.usdz',
    conclusionTitle: `Your Brancott Estate Pinot Noir, in your world.`,
    brand: 'BE',
    logo_src: './images/jc/branding/BE_logo.png',

    content: [
      {
        header: `It’s time to pour out\nsome Brancott Estate Pinot Noir!`,
        subtext:
          'Our structured yet elegant Pinot Noir with notes of black fruit and truffle accompanied by soft oak spices is the right match for you.',
      },
      {
        header: 'TASTE',
        subtext:
          'Grapefruit, melon, gooseberry and lime well structured with a crisp refreshing acidity with a balanced fruity finish.',
      },
      {
        header: 'ENJOY WITH',
        subtext: 'Vegetarian dishes and curries, seafood and salads.',
      },
    ],
  },
  // 9. Sauvigion Blanc
  {
    wineId: 9,
    title: 'Wine 02',
    url: './wine/Estate_Sauvigion_Blanc/Sauvigion_Blanc.gltf',
    hdr: 'skybox/jc_hdr/spruit_sunrise.hdr',
    bottleTexture: './wine/Estate_Sauvigion_Blanc/LM.jpg',
    wineName: 'Sauvigion Blanc',
    andriodAR: './_webar/BESauvignon.glb',
    iOSAR: './_webar/BESauvignon.usdz',
    conclusionTitle: `Your Brancott Estate Sauvignon Blanc, in your world.`,
    brand: 'BE',
    logo_src: './images/jc/branding/BE_logo.png',

    content: [
      {
        header: `It’s time to pour out\nsome Brancott Estate Sauvignon Blanc!`,
        subtext: `Our zesty, fruit forward Sauvignon Blanc with a crisp refreshing acidity is the right match for you.`,
      },
      {
        header: 'TASTE',
        subtext:
          'Red berries, blackberries, truffle, vanilla and sweet spices followed by a fruity, elegant and long finish.',
      },
      {
        header: 'ENJOY WITH',
        subtext: 'Pizzas, red sauce dishes, tandoori tikkas and mildly spiced curries.',
      },
    ],
  },
  // 10. Viejo Blanco
  {
    wineId: 10,
    title: 'Wine 03',
    url: './wine/Viejo_Blanco/Blanco.gltf',
    hdr: 'skybox/jc_hdr/spruit_sunrise.hdr',
    bottleTexture: './wine/Viejo_Blanco/CV_Blanco.png',
    wineName: 'Viejo Blanco',
    andriodAR: './_webar/CVBlanco.glb',
    iOSAR: './_webar/CVBlanco.usdz',
    conclusionTitle: `Your Campo Viejo Blanco in your world.`,
    brand: 'CV',
    logo_src: './images/jc/branding/CV_gold.png',

    content: [
      {
        header: `Our Campo Viejo Blanco is the right\npick for you!`,
        subtext:
          'Our fruity, refreshing and elegant Campo Viejo Blanco is the perfect match for you.',
      },
      {
        header: 'TASTE',
        subtext:
          'Citrus, lemon zest, green apple and white flowers, with a delicate body and high fruit intensity followed by a bright and fresh finish.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Seafood, chicken, white sauce dishes, vegetarian and lightly spiced curries.`,
      },
    ],
  },
  // 11. Viejo Tempranillo
  {
    wineId: 11,
    title: 'Wine 04',
    url: './wine/Viejo_Tempranillo/Tempranillo.gltf',
    hdr: 'skybox/jc_hdr/moonless_golf_b.hdr',
    bottleTexture: './wine/Viejo_Tempranillo/Night_LM.jpg',
    wineName: 'Viejo Tempranillo',
    andriodAR: './_webar/CVTempranillo.glb',
    iOSAR: './_webar/CVTempranillo.usdz',
    conclusionTitle: `Your Campo Viejo Tempranillo, in your world.`,
    brand: 'CV',
    logo_src: './images/jc/branding/CV_gold.png',

    content: [
      {
        header: `Our Campo Viejo Tempranillo is the right\npick for you!`,
        subtext:
          'Our fresh and lively, fruity expression of Tempranillo from Rioja, Spain with the subtle nuances of oak is the right match for you.',
      },
      {
        header: 'TASTE',
        subtext:
          'Ripe strawberries, cherries and plums accompanied by vanilla and sweet cocoa with a long, soft and fresh finish.',
      },
      {
        header: 'ENJOY WITH',
        subtext: `Roasts, grills & barbequed dishes and red sauce pastas.`,
      },
    ],
  },
]

export const diorama = [
  {
    diorama_id: 1,
    dioramaUrl: './diorama/sunday/sunday_afternoon.gltf',
    dioramaName: 'sunday_afternoon',
  },
  {
    diorama_id: 2,
    dioramaUrl: './diorama/romantic/romantic_dinner.gltf',
    dioramaName: 'romantic_dinner',
  },
  {
    diorama_id: 3,
    dioramaUrl: './diorama/movie/movie_night.gltf',
    dioramaName: 'movie_night',
  },
  {
    diorama_id: 4,
    dioramaUrl: './diorama/festive/Festive_dinner.gltf',
    dioramaName: 'festive_season',
  },
]

export const state = {
  top: 0,
  pages: 8,
  threshold: 5,
  mouse: [0, 0],
}
