import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useQuestion } from '../stores'

function QueBackButton() {
  const { setQueSetA, setQueSetB, setQueAId } = useQuestion()

  function onBackButton() {
    setQueSetB(false)
    setQueSetA(true)
    setQueAId(-1)
  }

  return (
    <button onClick={() => onBackButton()} className={css(styles.backButton)}>
      <img className={css(styles.IconStyle)} src="./images/jc/icons/BackButton.png" alt="Go Back" />
    </button>
  )
}

export default QueBackButton

const styles = StyleSheet.create({
  backButton: {
    pointerEvents: 'auto',
    cursor: 'pointer',
    position: 'absolute',
    top: '3.5vh',
    left: '3vh',
    background: 'none',
    padding: 0,
    margin: 0,
    border: 'none',
    width: '5.5vh',
    height: 'auto',
    zIndex: 2,
  },
  IconStyle: {
    height: '100%',
    width: '100%',
  },
})
