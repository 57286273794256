import create from 'zustand'

export const useQuestion = create((set) => ({
  queSetA: true,
  queSetB: false,
  queAId: -1,
  queBId: -1,
  optionUI: false,
  dioramaId: 0,
  setQueSetA: (bool) => set({ queSetA: bool }),
  setQueSetB: (bool) => set({ queSetB: bool }),
  setQueAId: (int) => set({ queAId: int }),
  setQueBId: (int) => set({ queBId: int }),

  setOptionUI: (bol) => set((state) => (state.optionUI = bol)),
  setDioramaId: (int) => set((state) => (state.dioramaId = int)),
}))

export const useWine = create((set) => ({
  wine3D: false,
  wineMapping: false,
  wineId: -1,
  scrollIndicator: false,
  buttonClicked: false,
  setWine3D: (bool) => set({ wine3D: bool }),
  setWineMapping: (bool) => set({ wineMapping: bool }),
  setWineId: (int) => set({ wineId: int }),
  setScrollIndicator: (bol) => set((state) => (state.scrollIndicator = bol)),
  setButtonClicked: (bol) => set((state) => (state.buttonClicked = bol)),
}))

export const useControls = create((set) => ({
  orbitControls: false,
  setOrbitControls: (bol) => set((state) => (state.orbitControls = bol)),
}))

export const useLoading = create((set) => ({
  wineLoading: false,
  logoLoading: false,
  leafLoading: false,
  setWineLoading: (bol) => set((state) => (state.wineLoading = bol)),
  setLogoLoading: (bol) => set((state) => (state.logoLoading = bol)),
  setLeafLoading: (bol) => set((state) => (state.leafLoading = bol)),
}))

export const useScrolling = create((set) => ({
  resetScroll: false,
  setResetScroll: (bol) => set((state) => (state.resetScroll = bol)),
}))

export const usePage = create((set) => ({
  currentPage: 'YearValidation',
  yearValidationScreen: true,
  landingPage: false,
  retryExp: false,
  setCurrentPage: (string) => set((state) => (state.currentPage = string)),
  setYearValidationScreen: (bol) => set((state) => (state.yearValidationScreen = bol)),
  setLandingPage: (bol) => set((state) => (state.landingPage = bol)),
  setRetryExp: (bol) => set((state) => (state.retryExp = bol)),
}))
