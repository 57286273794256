export function share_image(dioramaName, wineName, setShareImage) {
  // console.log('diorama' + dioramaName + 'wineName: ' + wineName)
  if (dioramaName === 'sunday_afternoon') {
    if (wineName === 'Chardonnay_Pinot Noir') {
      setShareImage('./Screenshots/SundayAfternoon_Pinot.jpg')
    } else if (wineName === 'Chardonnay') {
      setShareImage('./Screenshots/SundayAfternoon_Chardonnay.jpg')
    } else if (wineName === 'DoubleBarrel') {
      setShareImage('./Screenshots/SundayAfternoon_DB.jpg')
    } else if (wineName === 'Shiraz') {
      setShareImage('./Screenshots/SundayAfternoon_Cabernet.jpg')
    } else if (wineName === 'Cabernet Sauvignon') {
      setShareImage('./Screenshots/SundayAfternoon_CS.jpg')
    } else if (wineName === 'Merlot') {
      setShareImage('./Screenshots/SundayAfternoon_Merlot.jpg')
    } else if (wineName === 'Riesling') {
      setShareImage('./Screenshots/SundayAfternoon_Riesling.jpg')
    } else if (wineName === 'Estate_Pinot_Noir') {
      setShareImage('./Screenshots/BE_PinorNoir/SundayAfternoon.png')
    } else if (wineName === 'Sauvigion Blanc') {
      setShareImage('./Screenshots/BE_SauvigionBlanc/SundayAfternoon.png')
    } else if (wineName === 'Viejo Blanco') {
      setShareImage('./Screenshots/CV_Blanco/SundayAfternoon.png')
    } else if (wineName === 'Viejo Tempranillo') {
      setShareImage('./Screenshots/CV_Tempranillo/SundayAfternoon.png')
    }
  } else if (dioramaName === 'romantic_dinner') {
    if (wineName === 'Chardonnay_Pinot Noir') {
      setShareImage('./Screenshots/RomanticDinner_Pinot.jpg')
    } else if (wineName === 'Chardonnay') {
      setShareImage('./Screenshots/RomanticDinner_Chardonnay.jpg')
    } else if (wineName === 'DoubleBarrel') {
      setShareImage('./Screenshots/RomanticDinner_DB.jpg')
    } else if (wineName === 'Shiraz') {
      setShareImage('./Screenshots/RomanticDinner_Cabernet.jpg')
    } else if (wineName === 'Cabernet Sauvignon') {
      setShareImage('./Screenshots/RomanticDinner_CS.jpg')
    } else if (wineName === 'Merlot') {
      setShareImage('./Screenshots/RomanticDinner_Merlot.jpg')
    } else if (wineName === 'Riesling') {
      setShareImage('./Screenshots/RomanticDinner_Riesling.jpg')
    } else if (wineName === 'Estate_Pinot_Noir') {
      setShareImage('./Screenshots/BE_PinorNoir/RomanticDinner.png')
    } else if (wineName === 'Sauvigion Blanc') {
      setShareImage('./Screenshots/BE_SauvigionBlanc/RomanticDinner.png')
    } else if (wineName === 'Viejo Blanco') {
      setShareImage('./Screenshots/CV_Blanco/RomanticDinner.png')
    } else if (wineName === 'Viejo Tempranillo') {
      setShareImage('./Screenshots/CV_Tempranillo/RomanticDinner.png')
    }
  } else if (dioramaName === 'movie_night') {
    if (wineName === 'Chardonnay_Pinot Noir') {
      setShareImage('./Screenshots/MovieNight_Pinot.jpg')
    } else if (wineName === 'Chardonnay') {
      setShareImage('./Screenshots/MovieNight_Chardonnay.jpg')
    } else if (wineName === 'DoubleBarrel') {
      setShareImage('./Screenshots/MovieNight_DB.jpg')
    } else if (wineName === 'Shiraz') {
      setShareImage('./Screenshots/MovieNight_Cabernet.jpg')
    } else if (wineName === 'Cabernet Sauvignon') {
      setShareImage('./Screenshots/MovieNight_CS.jpg')
    } else if (wineName === 'Merlot') {
      setShareImage('./Screenshots/MovieNight_Merlot.jpg')
    } else if (wineName === 'Riesling') {
      setShareImage('./Screenshots/MovieNight_Riesling.jpg')
    } else if (wineName === 'Estate_Pinot_Noir') {
      setShareImage('./Screenshots/BE_PinorNoir/MovieNight.png')
    } else if (wineName === 'Sauvigion Blanc') {
      setShareImage('./Screenshots/BE_SauvigionBlanc/MovieNight.png')
    } else if (wineName === 'Viejo Blanco') {
      setShareImage('./Screenshots/CV_Blanco/MovieNight.png')
    } else if (wineName === 'Viejo Tempranillo') {
      setShareImage('./Screenshots/CV_Tempranillo/MovieNight.png')
    }
  } else if (dioramaName === 'festive_season') {
    if (wineName === 'Chardonnay_Pinot Noir') {
      setShareImage('./Screenshots/FestiveDinner_Pinot.jpg')
    } else if (wineName === 'Chardonnay') {
      setShareImage('./Screenshots/FestiveDinner_Chardonnay.jpg')
    } else if (wineName === 'DoubleBarrel') {
      setShareImage('./Screenshots/FestiveDinner_DB.jpg')
    } else if (wineName === 'Shiraz') {
      setShareImage('./Screenshots/FestiveDinner_Cabernet.jpg')
    } else if (wineName === 'Cabernet Sauvignon') {
      setShareImage('./Screenshots/FestiveDinner_CS.jpg')
    } else if (wineName === 'Merlot') {
      setShareImage('./Screenshots/FestiveDinner_Merlot.jpg')
    } else if (wineName === 'Riesling') {
      setShareImage('./Screenshots/FestiveDinner_Riesling.jpg')
    } else if (wineName === 'Estate_Pinot_Noir') {
      setShareImage('./Screenshots/BE_PinorNoir/FestiveDinner.png')
    } else if (wineName === 'Sauvigion Blanc') {
      setShareImage('./Screenshots/BE_SauvigionBlanc/FestiveDinner.png')
    } else if (wineName === 'Viejo Blanco') {
      setShareImage('./Screenshots/CV_Blanco/FestiveDinner.png')
    } else if (wineName === 'Viejo Tempranillo') {
      setShareImage('./Screenshots/CV_Tempranillo/FestiveDinner.png')
    }
  }
}
