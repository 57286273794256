export const QueContent = {
  setA: {
    tag: '1/2',
    title: 'SET YOUR MOOD...',
    options: [
      {
        id: 1,
        label: 'flying solo',
      },
      {
        id: 2,
        label: 'romantic dinner',
      },
      {
        id: 3,
        label: 'the weekend order-in',
      },
      {
        id: 4,
        label: 'celebration',
      },
      {
        id: 5,
        label: 'Gifting',
      },
    ],
  },
  setB: {
    tag: '2/2',
    title: 'WHAT ARE YOU EATING?',
    options: [
      {
        id: 1,
        label: 'Light Nibbles or cheese',
      },
      {
        id: 2,
        label: 'Seafood, chicken or white sauce dishes',
      },
      {
        id: 3,
        label: 'Pizzas or red sauce dishes',
      },
      {
        id: 4,
        label: 'Veg dishes and curries',
      },
      {
        id: 5,
        label: 'Grills, roasts and barbeque',
      },
      {
        id: 6,
        label: 'Tandoori kebabs or spicy meat curries',
      },
    ],
  },
}
